import { createSlice } from "@reduxjs/toolkit";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../App";

export const watchTable = (id) => (dispatch) => {
    if (!id) return;

    const docRef = doc(db, `tables`, id);
    return onSnapshot(docRef, async (doc) => {
        if (!doc.exists()) return;
        
        dispatch(saveTableId(id.split("_")[1]));
        
        const data = doc.data();
        dispatch(saveTableData({
            active: data.active,
            matchId: data.matchId,
            sponsors: data.sponsors,
        }));
    })
}

export const tableSlice = createSlice({
    name: 'table',
    initialState: {
        id: undefined,
        active: false,
        matchId: undefined,
        sponsors: [],
    },
    reducers: {
        saveTableId: (state, action) => {
            state.id = action.payload;
        },
        saveTableData: (state, action) => {
            state.active = action.payload.active;
            state.matchId = action.payload.matchId;
            state.sponsors = action.payload.sponsors;
        }
    }
})

export const { saveTableId, saveTableData } = tableSlice.actions;
export default tableSlice.reducer;