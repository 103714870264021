import { configureStore } from "@reduxjs/toolkit";
import matchReducer from './slices/matchSlice';
import tableReducer from './slices/tableSlice';
import venueReducer from './slices/venueSlice';

export default configureStore({
    reducer: {
        match: matchReducer,
        table: tableReducer,
        venue: venueReducer,
    },
    middleware: getDefaultMiddleware => 
        getDefaultMiddleware({
            thunk: {}
        }),
});
