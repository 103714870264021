import { createSlice } from "@reduxjs/toolkit";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../App";

export const watchMatch = () => (dispatch, getState) => {
    const matchId = getState().table.matchId;

    if (!matchId) return;

    const docRef = doc(db, `matches`, matchId);
    return onSnapshot(docRef, async (doc) => {
        if (!doc.exists()) return;

        const active = getState().table.active;
        console.log("ACTIVE:", active);

        const data = doc.data();
        dispatch(saveMatchData({
            host: data.host,
            guest: data.guest,
            hostScore: data.hostScore,
            guestScore: data.guestScore,
            hostRace: data.hostRace,
            guestRace: data.guestRace,
            description: data.description,
        }));
    })
}

export const matchSlice = createSlice({
    name: 'match',
    initialState: {
        host: "Waiting for player...",
        guest: "Waiting for player...",
        hostScore: 0,
        guestScore: 0,
        hostRace: 5,
        guestRace: 5,
        description: "",
        hostProfilePicUrl: undefined,
        guestProfilePicUrl: undefined,
    },
    reducers: {
        saveMatchData: (state, action) => {
                state.host = action.payload.host;
                state.guest = action.payload.guest;
                state.hostScore = action.payload.hostScore;
                state.guestScore = action.payload.guestScore;
                state.hostRace = action.payload.hostRace;
                state.guestRace = action.payload.guestRace;
                state.description = action.payload.description;
        },
        saveHostData: (state, action) => {
            state.hostData = {...action.payload}
        },
        saveGuestData: (state, action) => {
            state.guestData = {...action.payload}
        }
    }
})

export const { saveMatchData, saveHostData, saveGuestData } = matchSlice.actions;
export default matchSlice.reducer;