import React, { useState, useEffect } from 'react';
import { db } from '../App';
import { getDoc, doc, onSnapshot } from "firebase/firestore"; 


const Component = ({ scale=1.0, tableName, fieldName, initialData, position, color="white", stroke="black"}) => {
  const [data, setData] = useState(initialData);
  const fontSize = 18 * scale;
  const centeredFieldName = ["hostRace", "hostScore", "guestScore"];
  const widthFieldName = {
    "hostRace": "200px",
    "hostScore": "32px",
    "guestScore": "32px"
  }

  useEffect(() => {
    
  }, [tableName, fieldName, initialData]);

  return (
    <div className="component" style={{ fontSize: fontSize.toString() + "px", color: color, position: 'absolute', top: position.top, left: position.left, fontWeight: 'bold', WebkitTextStroke: stroke }}>
      <p style={{ textAlign: centeredFieldName.includes(fieldName) ? "center" : "left", width: widthFieldName.hasOwnProperty(fieldName) ? widthFieldName[fieldName] : "auto" }}>{data}</p>
    </div>
  );
};

export default Component;
