import Component from './Component';
import BackgroundComponent from './BackgroundComponent';

import evosportsLogoLandscape from '../assets/evosports-logo-landscape.png';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';

const styles = {
  scoreboard: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#FFFFFF",
    border: "solid",
    borderWidth: 5,
    borderColor: "#9D4BAD",
    borderRadius: 15,
    width: "80%",
    bottom: 50,
    height: 90,
    left: "50%",
    transform: "translate(-50%, 0)",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    letterSpacing: 2,
  },
  playerName: {
    width: "40%",
    textAlign: "center",
    justifyContent: "center",
    fontSize: 32,
    fontWeight: 900,
    paddingTop: 10,
  },
  gameInfo: {
    height: "75%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  race: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    verticalAlign: "middle",
    width: 100,
  },
  raceText: {
    fontSize: 15,
    fontWeight: 900,
  },
  raceNumber: {
    fontSize: 25,
    fontWeight: 900,
  },
  score: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#9D4BAD",
    borderRadius: 4,
    width: 70,
    fontSize: 40,
    fontWeight: 900,
    marginLeft: 5,
    marginRight: 5,
    lineHeight: "100%",
    paddingTop: 10,
  },
  evosportsLogoLandscape: {
    position: "absolute",
    left: 0, 
    right: 0,
    bottom: 5,
    marginLeft: "auto",
    marginRight: "auto",
    width: "225px",
  },
  scoreboardText: {
    color: "#ffffff",
    textTransform: "uppercase",
    fontSize: 23,
    letterSpacing: 2,
    textShadow: "-1.5px 0 black, 0 1.5px black, 1.5px 0 black, 0 -1.5px black"
  },
  venueName: {
    position: "absolute",
    left: "11%",
    bottom: 8,
  },
  tableNumber: {
    position: "absolute",
    right: "11%",
    bottom: 8,
  },
  description: {
    position: "absolute",
    width: "100%",
    textAlign: "center",
    bottom: 145,
  }
}

const scoreVariant = {
  "hidden": {
    y: -20, opacity: 0,
  },
  "visible": {
    y: 0, opacity: 1,
  },
  "exit": {
    y: 20, opacity: 0, position: "absolute",
  }
}

const Scoreboard = () => {
  const match = useSelector(state => state.match);
  const venue = useSelector(state => state.venue);
  const table = useSelector(state => state.table);

  return (
    <div className='overlay-content'>
        <div style={styles.scoreboard}>
          <div style={styles.playerName}>
            <p>{match && match.host.toUpperCase()}</p>
          </div>
          <div style={styles.gameInfo}>
            <div style={styles.score}>
              <AnimatePresence>
                {match && <motion.p
                  key={"hostScore" + match.hostScore}
                  variants={scoreVariant}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  >
                    {match.hostScore}
                  </motion.p>}
                </AnimatePresence>
            </div>
            <div style={styles.race}>
              <div style={styles.raceText}>Race to</div>
              <div style={styles.raceNumber}>{match.hostRace === match.guestRace ? match.hostRace : `${match.hostRace}/${match.guestRace}`}</div>
            </div>
            <div style={styles.score}>
              <AnimatePresence>
                {match && <motion.p
                  key={"guestScore" + match.guestScore}
                  variants={scoreVariant}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  >
                    {match.guestScore}
                </motion.p>}
              </AnimatePresence>
            </div>
          </div>
          <div style={styles.playerName}>
            {match && match.guest.toUpperCase()}
          </div>
        </div>
        <div style={styles.evosportsLogoLandscape}>
          <img src={evosportsLogoLandscape} />
        </div>
        <div style={{...styles.venueName, ...styles.scoreboardText}}>
          {venue && `${venue.name}, ${venue.city}, ${venue.state}`}
        </div>
        <div style={{...styles.tableNumber, ...styles.scoreboardText}}>
          Table {table && table.id}
        </div>
        <div style={{...styles.description, ...styles.scoreboardText}}>
          {match.description}
        </div>
    </div>
  );
};

export default Scoreboard;

